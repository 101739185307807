import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text-shimmer'
import BannerMultipleCtasShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas/banner-multiple-ctas-shimmer'
import BannerStickyShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky-shimmer'
import BannerImage2Shimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-2/banner-image-2-shimmer'

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text'
		),
	{ loading: BannerTextShimmer }
)

const BannerMultipleCtas = dynamic(
	() =>
		import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas'),
	{ loading: BannerMultipleCtasShimmer }
)

const BannerSticky = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky'
		),
	{ loading: BannerStickyShimmer }
)

const BannerImage2 = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-2/banner-image-2'
		),
	{ loading: BannerImage2Shimmer }
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerText,
}

const bannerMultipleCtasConfig = { component: BannerMultipleCtas, configAggregator: bannerConfigAggregator }

const bannerStickyConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSticky,
}

const bannerImage2Config = {
	configAggregator: bannerConfigAggregator,
	component: BannerImage2,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'banner-text': bannerTextConfig,
	'slide-text': bannerTextConfig,
	'banner-multiple-ctas': bannerMultipleCtasConfig,
	'banner-sticky': bannerStickyConfig,
	'banner-image-2': bannerImage2Config,
}

export default customContentTypes
